import { template as template_e5e20ef49815404f9ceeff225bf6448e } from "@ember/template-compiler";
const SidebarSectionMessage = template_e5e20ef49815404f9ceeff225bf6448e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
