import { template as template_0739d80fcfac4216a2771a4e8cc6b259 } from "@ember/template-compiler";
const FKLabel = template_0739d80fcfac4216a2771a4e8cc6b259(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
